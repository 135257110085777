/**
 * @param data
 * @param {string} data.boxName
 * @param {string} data.id
 * @param {string} data.title
 * @param {string} data.url
 * @param {number} data.position
 */
export const sendArticleClick = (data) =>
  sendArticleEvent('article_click', data)

/**
 * @param data
 * @param {string} data.name
 * @param {number} data.position
 */
export const sendWidgetImpression = (data) =>
  sendEvent({ event: 'widget_impression', widget: data })

/**
 * @param data
 * @param {string} data.name
 * @param {number} data.position
 * @param data.click
 * @param {string} data.click.value
 * @param {string} data.click.type
 * @param {string|undefined} data.click.position
 */
export const sendWidgetClick = (data) => {
  const { click, ...widget } = data
  return sendEvent({ widget, click, event: 'widget_click' })
}

/**
 * @param data
 * @param {string} data.name
 * @param {string} data.position
 * @param {string} data.message
 */
export const sendWidgetError = (data) => {
  const { message, ...widget } = data
  return sendEvent({ widget, event: 'widget_error', error: { message } })
}

export const sendUserLogin = () => sendEvent({ user: 'user_login_start' })

export const sendUserLoginSuccess = () => sendEvent({ user: 'user_login_success' })

export const sendUserLoginFail = () => sendEvent({ user: 'user_login_fail' })

/**
 * @param user
 * @param {string} user.id
 * @param {string} user.loginStatus - for example anonymous, LoggedIn
 */
export const sendUser = (user) => sendEvent({ user })

export const sendUserRenewPassword = () => sendEvent({ event: 'user_renewPassword_start' })

export const sendUserRenewPasswordSuccess = () => sendEvent({ event: 'user_renewPassword_success' })

export const sendUserRenewPasswordFail = () => sendEvent({ event: 'user_renewPassword_fail' })

/**
 * @param data
 * @param {string} data.vendor
 * @param {string} data.position
 * @param {string} data.itemPosition
 */
export const sendSearchAdsImpression = (data) => sendSearchAdsEvent('search_ads_impression', data)

/**
 * @param data
 * @param {string} data.vendor
 * @param {string} data.position
 * @param {string} data.itemPosition
 */
export const sendSearchAdsClick = (data) => sendSearchAdsEvent('search_ads_click', data)

/**
 * @param {number} position
 */
export const sendSearchOrganicImpression = (position) => sendEvent({
  event: 'search_organic_impression',
  item: { position }
})

/**
 * @param data
 * @param {string} data.value
 * @param {number} data.position
 */
export const sendSearchOrganicClick = (data) => sendEvent({
  event: 'search_organic_click',
  item: data
})

/**
 * @param page
 * @param {string} page.type - for example homepage
 * @param {string} page.template
 */
export const sendAdImpression = (page) => sendEvent({ page, event: 'ad_impression' })

/**
 * @param data
 * @param {string} data.articleId
 * @param {string} data.type - for example homepage
 * @param {string} data.template
 */
export const sendAdClick = (data) => {
  const { articleId, ...page } = data
  return sendEvent({
    page,
    article: { id: articleId },
    event: 'ad_click'
  })
}

/**
 * @param page
 * @param {string} page.type - for example homepage
 * @param {string} page.template
 */
export const sendAdError = (page) => sendEvent({ page, event: 'ad_error' })

const sendEvent = (data) => {
  if (!window) {
    return
  }
  if (!window.dataLayer) {
    window.dataLayer = []
  }
  return window.dataLayer.push({
    ...data,
    _clear: true
  })
}

const sendArticleEvent = (event, data) => {
  const { boxName, ...item } = data
  sendEvent({
    event,
    item,
    box: {
      name: boxName
    }
  })
}

/**
 * @param data
 * @param {string} event
 * @param {string} data.vendor
 * @param {string} data.position
 * @param {string} data.itemPosition
 */
const sendSearchAdsEvent = (event, data) => {
  const { itemPosition, ...ads } = data
  return sendEvent(event, {
    ads,
    item: { position: itemPosition }
  })
}
